import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function PaymentSuccess() {
  setTimeout(() => {
    window.location.href = '/account';
  }, 3000);
  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Thank you! You are now a tetun.org supporter.
        </Typography>
        <Box sx={{ padding: 3 }}></Box>
      </Paper>
    </Box>
  );
}
