// SnackbarComponent.js

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNotification } from './NotificationContext';

const ErrorSnackbar = () => {
  const { notification, severity, hideNotification } = useNotification();
  const open = Boolean(notification);

  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === 'timeout') {
          hideNotification();
        }
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
    >
      <MuiAlert severity={severity} elevation={6} onClose={hideNotification}>
        {notification}
      </MuiAlert>
    </Snackbar>
  );
};

export default ErrorSnackbar;
