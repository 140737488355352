import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import { useConfig } from '../ConfigContext';
import axios from 'axios';

export default function ConfirmEmail() {
  const { apiUrl } = useConfig();
  const { key } = useParams();
  const [text, setText] = React.useState('Verifying email...');

  const verifyEmail = async () => {
    const url = `${apiUrl}/dj-rest-auth/registration/verify-email/`;
    try {
      await axios.post(url, { key: key });
      setText('Email verified!');
    } catch (error) {
      alert('Error verifying email');
      console.log(error);
    }
  };

  React.useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          {text}
        </Typography>
        <Box sx={{ padding: 3 }}></Box>
      </Paper>
    </Box>
  );
}
