import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

import SwitchToLogin from './SwitchToLogin';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import DialogSubtitle from './DialogSubtitle';
import MyTextField from './MyTextField';
import { useUser } from './UserContext';
import { useNotification } from './NotificationContext';

function Signup() {
  const navigate = useNavigate();
  const { register } = useUser();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);
  const { showNotification } = useNotification();

  const handlePrev = () => {
    navigate('/account/upgrade', { replace: true });
  };

  const handleSignup = async () => {
    setSubmitted(true);

    if (!email || !password) {
      return;
    }

    try {
      setLoading(true);
      const registerSuccess = await register(
        firstName,
        lastName,
        email,
        password
      );
      if (!registerSuccess) {
        return;
      }
      // navigate to /account/verify
      navigate('/account/verify', { replace: true });
      // await login(email, password);
      // await checkout(email);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        showNotification(error.response.data);
      } else {
        showNotification(error.message);
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {' '}
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingY: 10,
          maxWidth: 700,
          margin: 'auto', // to center the Paper in the viewport
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Create an account
        </Typography>
        <Box sx={{ paddingX: 3 }}>
          <DialogSubtitle>
            {/* Access higher-quality translation, without character limit, to and
            from over 100 languages. */}
          </DialogSubtitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
            }}
          >
            <MyTextField
              label="First name"
              type="text"
              name="first_name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <MyTextField
              label="Last name"
              type="text"
              name="last_name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Box>
          <MyTextField
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={submitted && !email}
            helperText={submitted && !email ? 'Email is required' : ''}
          />
          <MyTextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={submitted && !password}
            helperText={submitted && !password ? 'Password is required' : ''}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingY: 2,
            paddingX: 3,
          }}
        >
          <SecondaryButton
            onClick={handlePrev}
            startIcon={<ArrowBackIosIcon />}
          >
            Previous: Select Plan
          </SecondaryButton>
          <PrimaryButton
            onClick={handleSignup}
            disabled={!email || !password || loading}
          >
            Next: Payment
          </PrimaryButton>
        </Box>
      </Paper>
      <SwitchToLogin
        text="Already have an account?"
        linkHref="/account/login"
        linkText="Login"
      />
    </Box>
  );
}

export default Signup;
