import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const SwitchToLogin = ({ text, linkHref, linkText }) => {
  return (
    <Typography
      variant="body2"
      sx={{
        color: '#4f4f4f',
        textAlign: 'center',
        marginTop: 2,
      }}
    >
      {text} <Link to={linkHref}>{linkText}</Link>
    </Typography>
  );
};

export default SwitchToLogin;
