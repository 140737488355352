import React from 'react';
import Button from '@mui/material/Button';

const SecondaryButton = ({ onClick, children, ...props }) => (
  <Button
    variant="text"
    color="primary"
    sx={{ color: 'primary', ...props.sx }}
    onClick={onClick}
    startIcon={props.startIcon}
  >
    {children}
  </Button>
);

export default SecondaryButton;
