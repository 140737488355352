import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

export default function LoginGoogle() {
  const { socialLogin } = useUser();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (googleResponse) => {
      socialLogin(googleResponse).then(() => {
        navigate('/account/details');
      });
    },
    flow: 'auth-code',
  });

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Login with Google
        </Typography>
        <Box sx={{ padding: 3 }}>
          {/* <DialogSubtitle>
            If you already have an account, please login below.
          </DialogSubtitle> */}
          {/* <GoogleLogin onSuccess={responseGoogle} onError={handleFailure} /> */}
          <Button onClick={() => login()}>Sign in with Google 🚀</Button>
        </Box>
      </Paper>
    </Box>
  );
}
