import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

import { wordInDictionary } from "./stringSimilarity";

const numberRegex = new RegExp(/^[\d$.,]+$/, "gm");
const pluralRegex = new RegExp(/[a|e|i|o|u]s\b/);
const splittedRegex = new RegExp(/([\s\n"“”.…()/,:;!?])/, "gm");

function findProblems(doc) {
  const problems = [];

  // For each node in the document
  doc.descendants((node, pos) => {
    if (node.isText) {
      const words = node.text.split(splittedRegex);
      words.forEach((word, index) => {
        // replace Right Single Quotation Mark with Apostrophe
        // eslint-disable-next-line no-param-reassign
        word = word.replace("’", "'");
        if (word.match(splittedRegex)) return;
        if (word.match(numberRegex)) return;

        // suggetion for ha'u nia -> ha'u-nia
        if (
          index >= 2 &&
          words[index - 1] === " " &&
          word === "nia" &&
          ["ha'u", "ó", "ita", "imi"].indexOf(words[index - 2].toLowerCase()) >
            -1
        ) {
          const wordWithPrevious =
            words[index - 2] + words[index - 1] + word.trim();
          const strIndex = words.slice(0, index - 2).join("").length;
          problems.push({
            from: pos + strIndex,
            to: pos + strIndex + wordWithPrevious.length,
            word: wordWithPrevious,
          });
          return;
        }

        if (wordInDictionary(word.trim(), window.dictionaries["tet-en"]))
          return;
        if (word.length > 1 && word.toUpperCase() === word) return; // acronym
        // word is the plural of a known word
        if (
          word.match(pluralRegex) &&
          wordInDictionary(
            word.slice(0, word.length - 1),
            window.dictionaries["tet-en"]
          )
        )
          return;

        // word starts with capital letter and is not start of sentence, ignore
        if (
          index >= 3 &&
          word.substr(0, 1).toUpperCase() === word.substr(0, 1) &&
          ["\n", ".", "!", "?"].indexOf(words[index - 3]) === -1
        ) {
          return;
        }
        if (
          index === 2 &&
          word.substr(0, 1).toUpperCase() === word.substr(0, 1)
        )
          return;

        if (word.indexOf("na'in-") === 0 || word.indexOf("eis-") === 0) return;

        // see if we can find a suggestion by removing space, or by replacing space with '-'
        if (
          index >= 2 &&
          words[index - 1] === " " &&
          (wordInDictionary(
            words[index - 2] + word.trim(),
            window.dictionaries["tet-en"]
          ) ||
            wordInDictionary(
              `${words[index - 2]}-${word.trim()}`,
              window.dictionaries["tet-en"]
            ))
        ) {
          const wordWithPrevious =
            words[index - 2] + words[index - 1] + word.trim();
          const strIndex = words.slice(0, index - 2).join("").length;
          problems.push({
            from: pos + strIndex,
            to: pos + strIndex + wordWithPrevious.length,
            word: wordWithPrevious,
          });
          return;
        }

        // add up the length of elements until index
        const strIndex = words.slice(0, index).join("").length;
        problems.push({
          from: pos + strIndex,
          to: pos + strIndex + word.length,
          word,
        });
      });
    }
  });

  return problems;
}

function addProblemDecorations(doc) {
  const decos = [];
  findProblems(doc).forEach((prob) => {
    // from, to are the positions at which to insert the decoration
    decos.push(
      Decoration.inline(prob.from, prob.to, {
        class: "problem",
        word: prob.word,
        from: prob.from,
        to: prob.to,
      })
    );
  });
  return DecorationSet.create(doc, decos);
}

const addProblemPlugin = new Plugin({
  state: {
    init(_, { doc }) {
      return addProblemDecorations(doc);
    },
    apply(tr, old) {
      return tr.docChanged ? addProblemDecorations(tr.doc) : old;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state);
    },
  },
});

export default addProblemPlugin;
