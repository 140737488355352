import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Input as BaseInput, InputProps } from '@mui/base/Input';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { useUser } from './UserContext';

const Input = React.forwardRef(function CustomInput(
  props: InputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <BaseInput
      slots={{
        root: RootDiv,
        input: 'input',
        textarea: TextareaElement,
      }}
      {...props}
      ref={ref}
    />
  );
});

export default function InputMultilineAutosize({
  onChange,
  value,
  onClear,
}: any) {
  const { t } = useTranslation();
  const { user } = useUser();
  const [textLimit, setTextLimit] = React.useState(
    user.isPaying ? 500000 : 3000
  );
  const [charCount, setCharCount] = React.useState(0);

  React.useEffect(() => {
    setTextLimit(user.isPaying ? 500000 : 3000);
  }, [user.isPaying]);

  const handleChange = (e: any) => {
    // get the first textLimit chars
    const text = e.target.value.slice(0, textLimit);
    setCharCount(text.length);
    onChange(text);
  };

  const handleClear = () => {
    setCharCount(0);
    onClear();
  };

  const fontSize = value && value.length < 100 ? '1rem' : '0.9rem';

  return (
    <div style={{ position: 'relative' }}>
      <Input
        aria-label="Text to translate"
        multiline
        placeholder={t('enter_text')}
        onChange={handleChange}
        value={value}
        autoFocus={true}
        minRows={2}
        style={{ fontSize }}
      />
      {value && value.length > 0 && (
        <IconButton
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            zIndex: 1,
          }}
          onClick={handleClear}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      {!user.isPaying && (
        <div
          style={{
            fontSize: '0.6rem',
            color: 'grey',
            position: 'absolute',
            right: 5,
            bottom: 5,
          }}
        >
          {charCount}/{textLimit}
        </div>
      )}
    </div>
  );
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const RootDiv = styled('div')`
  display: flex;
  max-width: 100%;
`;

const TextareaElement = styled(TextareaAutosize)(
  ({ theme }) => `
  width: 100%;
  resize: vertical;
  min-height: 60px;
  font-weight: 400;
  font-family: inherit;
  font-size: inherit;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 0 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.07);


  &:focus {
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0pt 0.5px var(--theme-color);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
