import React, { createContext, useState, useCallback } from 'react';
import ErrorSnackbar from './ErrorSnackbar';

const NotificationContext = createContext();

export const useNotification = () => React.useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  const [severity, setSeverity] = useState('error');
  const [notificationTimeout, setNotificationTimeout] = useState(6000);

  const showNotification = useCallback(
    (message, severity = 'error', rawHtml = false, timeout = 6000) => {
      if (rawHtml) {
        setNotification(message);
      } else {
        setNotification(convertToString(message));
      }
      setSeverity(severity);
      setNotificationTimeout(timeout);
    },
    []
  );

  const convertToString = (message) => {
    // if message is an object, convert it to string
    // through showing key: JSON.stringify(value) for each key-value pair
    if (typeof message === 'object') {
      let messageString = '';
      for (const key in message) {
        const value = message[key];
        // if value is an array, convert it to string through ", ".join(value)
        if (Array.isArray(value)) {
          messageString += `${key}: ${value.join(', ')}\n`;
          continue;
        } else {
          messageString += `${key}: ${JSON.stringify(message[key])}\n`;
        }
      }
      message = messageString;
    }
    return message;
  };

  const hideNotification = useCallback(() => {
    setNotification(null);
  }, []);

  React.useEffect(() => {
    if (notification) {
      setTimeout(() => {
        hideNotification();
      }, notificationTimeout);
    }
  }, [notification, hideNotification]);

  return (
    <NotificationContext.Provider
      value={{ notification, severity, showNotification, hideNotification }}
    >
      <ErrorSnackbar />
      {children}
    </NotificationContext.Provider>
  );
};
