// ConfigContext.js
import React, { createContext, useContext } from 'react';

const ConfigContext = createContext();

// eslint-disable-next-line react/prop-types
export const ConfigProvider = ({ children }) => {
  const apiUrl =
    // eslint-disable-next-line no-undef
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000'
      : 'https://api.tetun.org';

  return (
    <ConfigContext.Provider value={{ apiUrl }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => useContext(ConfigContext);
