import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useNotification } from './NotificationContext';

import PrimaryButton from './PrimaryButton';
import DialogSubtitle from './DialogSubtitle';
import { useUser } from './UserContext';

const BenefitItem = ({ text }) => (
  <ListItem sx={{ padding: 0 }}>
    <ListItemIcon
      sx={{
        minWidth: '2rem',
        color: 'primary.main', // Set the color to primary
      }}
    >
      <Check fontSize="14" />
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const PricingElement = ({ amount, periodText }) => (
  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
    <Typography
      component="span"
      sx={{ fontSize: '14px', fontWeight: 500, marginRight: '2px' }}
    >
      $
    </Typography>
    <Typography
      component="span"
      sx={{ fontSize: '32px', fontWeight: 500, lineHeight: 1 }}
    >
      {amount}
    </Typography>
    <Typography
      component="span"
      sx={{
        fontSize: '14px',
        color: '#4f4f4f',
        alignSelf: 'flex-end',
        paddingBottom: '4px',
      }}
    >
      {periodText}
    </Typography>
  </Box>
);

const PaperHeader = ({ children }) => (
  <Typography
    variant="h6"
    sx={{ fontSize: '1rem', marginBottom: 0 }}
    gutterBottom
  >
    {children}
  </Typography>
);

function Upgrade() {
  const { user, socialLogin, checkout } = useUser();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPlan, setSelectedPlan] = React.useState(
    user.plan || 'monthly'
  );
  const { showNotification } = useNotification();

  // const handleChange = (event) => {
  //   setSelectedPlan(event.target.value);
  //   // updateUser({ plan: event.target.value });
  // };
  //
  // const handleNext = () => {
  //   navigate('/account/signup', { state: { plan: selectedPlan } });
  // };

  const signupWithGoogle = useGoogleLogin({
    onSuccess: (googleResponse) => {
      socialLogin(googleResponse).then(() => {
        checkout();
      });
    },
    onError: () => {
      showNotification(arguments);
    },
    flow: 'auth-code',
  });

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (googleResponse) => {
      socialLogin(googleResponse).then(() => {
        navigate('/account/details');
      });
    },
    onError: () => {
      showNotification(arguments);
    },
    flow: 'auth-code',
  });

  return (
    <Box>
      {' '}
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingY: 10,
          maxWidth: 700,
          margin: 'auto', // to center the Paper in the viewport
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Support us & upgrade
        </Typography>
        <Box sx={{ paddingX: 3, marginBottom: 3 }}>
          <DialogSubtitle>
            Help keep tetun.org free for over 60,000 users.
            <br />
            Save time through fast translation of large documents.
          </DialogSubtitle>
          {/* <FormControl
            component="fieldset"
            sx={{ width: "100%", marginBottom: 2 }}
          >
            <PaperHeader>Select your plan</PaperHeader>
            <RadioGroup
              row
              name="plan"
              value={selectedPlan}
              onChange={handleChange}
              sx={{
                justifyContent: "space-between",
                width: "100%",
                marginTop: 1,
              }}
            >
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label="Monthly"
                style={{
                  ...formControlLabelStyle,
                  marginRight: "10px",
                }}
              />
              <FormControlLabel
                value="annual"
                control={<Radio />}
                style={{
                  ...formControlLabelStyle,
                }}
                label={
                  <Typography
                    component="span"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>Annual</Box>
                    <Box
                      sx={{
                        backgroundColor: "#2B2B2B",
                        color: "white",
                        borderRadius: "5px",
                        paddingX: "7px",
                        fontSize: "0.7rem",
                        textTransform: "uppercase",
                      }}
                    >
                      Best value
                    </Box>
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl> */}
          <PaperHeader>What you get</PaperHeader>
          <List>
            <BenefitItem text="Support our service, and future improvements." />
            <BenefitItem text="Translate without any character limit." />
            {/* <BenefitItem text="Document translation (Word, PDF)" /> */}
            <BenefitItem text="Translate to/from 20 languages." />
            {/* <BenefitItem text="Higher-quality translation" /> */}
          </List>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingY: 2,
            paddingX: 3,
          }}
        >
          <PricingElement
            amount={selectedPlan === 'annual' ? '100' : '10'}
            periodText={
              selectedPlan === 'annual' ? '/year' : '/mo (cancel anytime)'
            }
          />
          <PrimaryButton onClick={signupWithGoogle}>
            Sign up with Google
          </PrimaryButton>
        </Box>
      </Paper>
      <Typography
        variant="body2"
        sx={{
          color: '#4f4f4f',
          textAlign: 'center',
          marginTop: 2,
        }}
      >
        Already have an account?{' '}
        <Link onClick={loginWithGoogle} style={{ cursor: 'pointer' }}>
          Login
        </Link>
      </Typography>
    </Box>
  );
}

export default Upgrade;
