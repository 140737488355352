import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import SwitchToLogin from '../SwitchToLogin';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import DialogSubtitle from '../DialogSubtitle';
import { useUser } from '../UserContext';
import { useConfig } from '../ConfigContext';
import { useNotification } from '../NotificationContext';

function AccountDetails() {
  const { user, checkout } = useUser();
  const { apiUrl } = useConfig();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const navigateLogout = () => {
    navigate('/account/logout');
  };

  const getStripePortal = async () => {
    const url = `${apiUrl}/users/get-stripe-portal/`;
    setLoading(true);
    try {
      const response = await axios.post(url);
      window.location.href = response.data.url;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 302) {
          window.location.href = error.response.data.url;
        } else if (error.response.status === 400 && error.response.data.error) {
          if (
            error.response.data.error ===
            'No customer object associated with your user.'
          ) {
            await checkout();
            return;
          }
          showNotification(error.response.data.error);
        } else {
          showNotification(JSON.stringify(error.response.data));
        }
      } else {
        showNotification(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {' '}
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingY: 10,
          maxWidth: 700,
          margin: 'auto', // to center the Paper in the viewport
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Your tetun.org account
        </Typography>
        <Box sx={{ paddingX: 3, paddingBottom: 3 }}>
          <DialogSubtitle>
            {user.isPaying
              ? 'Thank you for your support!'
              : 'Your subscription has expired. Please renew it under Manage Subscription'}
          </DialogSubtitle>
          <Box>
            {user.firstName || ''} {user.lastName || ''}
          </Box>
          <Box>{user.email}</Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingY: 2,
            paddingX: 3,
          }}
        >
          <SecondaryButton onClick={navigateLogout}>Logout</SecondaryButton>
          <PrimaryButton onClick={getStripePortal} disabled={loading}>
            Manage subscription
          </PrimaryButton>
        </Box>
      </Paper>
      <SwitchToLogin
        text="Need help?"
        linkHref="mailto:info@tetun.org"
        linkText="Email us"
      />
    </Box>
  );
}

export default AccountDetails;
