/* eslint-disable quote-props */
import _ from "lodash";

const wordPairs = {
  see: "sé",
  haan: "han",
  xavi: "xave",
  senyor: "señór",
  nauk: "na'ok",
  los: "loos",
  at: "aat",
  aan: "an",
  foo: "fó",
  lorokraik: "lokraik",
  mák: "mak",
  spesifiku: "espesífiku",
  tamba: "tanba",
  vídio: "vídeo",
  nbe: "ne'ebé",
  nebe: "ne'ebé",
  neebe: "ne'ebé",
  neeba: "ne'ebá",
  "nee'ebe": "ne'ebé",
  "feto-aat": "feto-nona",
  laos: "la'ós",
  konaba: "kona-ba",
  nudar: "nu'udar",
  involve: "envolve",
  "aten-brani": "aten-barani",
  minarai: "mina-rai",
  "ida-neebe": "ida-ne'ebé",
  "sira-neebe": "sira-ne'ebé",
  hun: "huun",
  "ai-hun": "ai-huun",
  nacao: "nasaun",
  desde: "dezde",
  nusaa: "nu'usá",
  ne: "ne'e",
  hw: "ha'u",
  w: "ha'u",
  itoan: "uitoan",
  ituan: "uituan",
  nomor: "númeru",
  kalae: "ka lae",
  labele: "la bele",
  dk: "di'ak",
  mk: "mak",
  idaneebe: "ida-ne'ebé",
  ga: "ka",
};

/**
 * Returns the Damerau-Levenshtein distance between s1 and s2
 * @param {string} s1
 * @param {string} s2
 * @returns {number}
 */
function stringDistance(source, target) {
  if (!source) return target ? target.length : 0;
  if (!target) return source.length;

  const m = source.length;
  const n = target.length;
  const INF = m + n;
  const score = new Array(m + 2);
  const sd = {};
  for (let i = 0; i < m + 2; i += 1) score[i] = new Array(n + 2);
  score[0][0] = INF;
  for (let i = 0; i <= m; i += 1) {
    score[i + 1][1] = i;
    score[i + 1][0] = INF;
    sd[source[i]] = 0;
  }
  for (let j = 0; j <= n; j += 1) {
    score[1][j + 1] = j;
    score[0][j + 1] = INF;
    sd[target[j]] = 0;
  }

  for (let i = 1; i <= m; i += 1) {
    let DB = 0;
    for (let j = 1; j <= n; j += 1) {
      const i1 = sd[target[j - 1]];
      const j1 = DB;
      if (source[i - 1] === target[j - 1]) {
        score[i + 1][j + 1] = score[i][j];
        DB = j;
      } else {
        score[i + 1][j + 1] =
          Math.min(score[i][j], Math.min(score[i + 1][j], score[i][j + 1])) + 1;
      }
      score[i + 1][j + 1] = Math.min(
        score[i + 1][j + 1],
        score[i1] ? score[i1][j1] + (i - i1 - 1) + 1 + (j - j1 - 1) : Infinity
      );
    }
    sd[source[i - 1]] = i;
  }
  return score[m + 1][n + 1];
}

/**
 * Returns the string similarity between 0 and 1, where 1 means strings are the same.
 * @param {string} s1
 * @param {string} s2
 * @returns {number}
 */
function stringSimilarity(s1, s2) {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (
    (longerLength - stringDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

export function wordInDictionary(word, dictionary) {
  if (!dictionary) return false;
  const cleanedWord = word.replace(/[,.]/gm, "").replace("’", "'");
  return (
    dictionary[cleanedWord] !== undefined ||
    dictionary[cleanedWord.toLowerCase()] !== undefined
  );
}

export function closestWord(word, dictionary) {
  const searchText = word.toLowerCase();
  if (wordPairs[searchText]) return wordPairs[searchText];
  if (
    searchText.indexOf("aa") > -1 &&
    wordInDictionary(
      searchText.replace("aa", "á"),
      window.dictionaries["tet-en"]
    )
  ) {
    return searchText.replace("aa", "á");
  }
  const possibleWords = _(dictionary);
  const firstLetters = searchText.slice(0, 1);
  let bestCandidate = { word: "", score: 0.0 };
  possibleWords.keys().forEach((s) => {
    if (!_.startsWith(_.lowerCase(s), firstLetters)) return;
    const similarityScore = stringSimilarity(_.lowerCase(s), searchText);
    if (similarityScore >= 0.75 && similarityScore > bestCandidate.score) {
      bestCandidate = { word: s, score: similarityScore };
    }
  });
  return bestCandidate.word;
}
