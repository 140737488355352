import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LogRocket from 'logrocket';

import './index.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from './UserContext';
import { ConfigProvider } from './ConfigContext';
import { NotificationProvider } from './NotificationContext';

import translations from './translations';
import reportWebVitals from './reportWebVitals';
import router from './router';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

serviceWorkerRegistration.register();

if (process.env.NODE_ENV === 'production' && Math.random() < 0.01) {
  LogRocket.init('jymlud/tetunorg');
}

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: translations,
  lng: window.localStorage.getItem('interfaceLanguageCode') || 'en',
});

declare global {
  interface Window {
    dictionaries: {
      [key: string]: any;
    };
  }
}

window.dictionaries = { 'en-tet': {}, 'tet-en': {} };

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
const theme = createTheme({
  typography: {
    fontFamily: 'Rubik, sans-serif',
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
  palette: {
    primary: {
      main: '#ea7e64',
      light: '#fae1c9',
      dark: '#BA6D59',
    },
  },
});

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <NotificationProvider>
            <UserProvider>
              <GoogleOAuthProvider clientId="718419948749-srm8bc13r9epvjtkrs1cocnk4k75r5hj.apps.googleusercontent.com">
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <div className="app-container" id="app-container">
                    <RouterProvider router={router} />
                  </div>
                </ThemeProvider>
              </GoogleOAuthProvider>
            </UserProvider>
          </NotificationProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
