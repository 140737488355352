import React, { useEffect, useRef } from 'react';
import { EditorState, Plugin } from 'prosemirror-state';
import { DOMParser, Schema } from 'prosemirror-model';
import { baseKeymap } from 'prosemirror-commands';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { keymap } from 'prosemirror-keymap';
import { undo, redo, history } from 'prosemirror-history';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import DataLoader from '../DataLoader';
import NavigationRow from '../NavigationRow';
import SuggestionTooltip from '../utils/SuggestionTooltip';
import addProblemPlugin from '../utils/AddProblemPlugin';
import './SpellChecker.css';

const ignoreEnterIfTooltipActive = () => {
  // if no active tooltip, return false to use the next handler
  if (!document.querySelector('#editor .tooltip .highlighted')) return false;
  // plugin will take care of accepting the suggestion
  return true;
};

const placeholderPlugin = new Plugin({
  props: {
    // eslint-disable-next-line consistent-return
    decorations(state) {
      const doc = state.doc;
      if (
        doc.childCount === 1 &&
        doc.firstChild.isTextblock &&
        doc.firstChild.content.size === 0
      ) {
        const placeholder = document.createElement('span');
        placeholder.classList.add('placeholder');
        placeholder.appendChild(document.createTextNode('Text to spell check'));
        return DecorationSet.create(doc, [Decoration.widget(1, placeholder)]);
      }
    },
  },
});

const suggestionTooltipPlugin = new Plugin({
  view(editorView) {
    return new SuggestionTooltip(editorView);
  },
});

const saveRetrieveDocPlugin = new Plugin({
  state: {
    init(config, state) {
      const strContent = localStorage.getItem('spellcheck-content');
      if (strContent) {
        try {
          state.doc = state.schema.nodeFromJSON(JSON.parse(strContent));
        } catch (error) {
          localStorage.setItem('spellcheck-content', '');
        }
      }
    },
    apply(tr) {
      if (tr.docChanged) {
        localStorage.setItem(
          'spellcheck-content',
          JSON.stringify(tr.doc.toJSON())
        );
      }
    },
  },
});

const textSchema = new Schema({
  nodes: {
    doc: { content: 'paragraph+' },
    paragraph: {
      content: 'text*',
      toDOM() {
        return ['p', 0];
      },
    },
    text: { inline: true },
  },
});

const SpellChecker = () => {
  const editorRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    const contentElement = document.createElement('div');
    contentElement.style.display = 'none';
    const savedContent = localStorage.getItem('spellcheck-content');
    if (savedContent) {
      contentElement.innerHTML = savedContent;
    }
    const state = EditorState.create({
      doc: DOMParser.fromSchema(textSchema).parse(contentElement),
      plugins: [
        saveRetrieveDocPlugin,
        addProblemPlugin,
        placeholderPlugin,
        suggestionTooltipPlugin,
        history(),
        keymap({
          'Mod-z': undo,
          'Mod-y': redo,
          Enter: ignoreEnterIfTooltipActive,
        }),
        keymap(baseKeymap),
      ],
    });

    const view = new EditorView(editorRef.current, {
      state,
      attributes: { spellcheck: 'false' },
    });
    view.focus();

    return () => {
      view.destroy();
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: 'rgb(250,250,250)',
        height: '100%',
      }}
    >
      <DataLoader />
      <Container
        maxWidth="sm"
        className="my-4"
        sx={{
          pb: 10,
        }}
      >
        <div className="my-4" ref={editorRef} id="editor">
          <Typography variant="h5" component="h1" gutterBottom>
            Spell Check
          </Typography>
          <Typography
            variant="body1"
            component="p"
            gutterBottom
            sx={{ fontSize: '0.8rem', mb: '1rem' }}
            dangerouslySetInnerHTML={{ __html: t('spell_check_help') }}
          ></Typography>
        </div>
      </Container>
      <NavigationRow />
    </div>
  );
};

const StyledSpellChecker = styled(SpellChecker)(
  () => `
`
);

export default StyledSpellChecker;
