import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import SwitchToLogin from '../SwitchToLogin';
import PrimaryButton from '../PrimaryButton';
import MyTextField from '../MyTextField';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, isLoggedIn } = useUser();
  const navigate = useNavigate();

  if (isLoggedIn) {
    navigate('/account/details');
  }

  const handleLogin = async () => {
    setLoading(true);
    try {
      const loginSuccess = await login(email, password);
      if (loginSuccess) {
        navigate('/account/details');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Login
        </Typography>
        <Box sx={{ padding: 3 }}>
          {/* <DialogSubtitle>
            If you already have an account, please login below.
          </DialogSubtitle> */}
          <MyTextField
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <MyTextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 0 }}
          >
            <Link
              to="/account/reset-password"
              style={{ textDecoration: 'none' }}
            >
              <Typography
                variant="body2"
                color="primary"
                sx={{ fontWeight: 400 }}
              >
                Forgot password?
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <PrimaryButton
              onClick={handleLogin}
              disabled={!email || !password || loading}
            >
              Login
            </PrimaryButton>
          </Box>
        </Box>
      </Paper>
      <SwitchToLogin
        text="Don't have an account?"
        linkHref="/account"
        linkText="Signup"
      />
    </Box>
  );
}
