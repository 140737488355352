import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import DialogSubtitle from '../DialogSubtitle';
import SecondaryButton from '../SecondaryButton';

export default function Account() {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate('/account/login');
  };
  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Reset link sent!
        </Typography>
        <Box sx={{ padding: 3 }}>
          <DialogSubtitle>
            Please check your email to reset your password.
          </DialogSubtitle>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingY: 2,
            paddingX: 3,
          }}
        >
          <SecondaryButton onClick={handleBackToLogin}>
            Back to login
          </SecondaryButton>
        </Box>
      </Paper>
    </Box>
  );
}
