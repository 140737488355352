/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Badge, { BadgeProps } from '@mui/material/Badge';
import styled from '@mui/material/styles/styled';

import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LanguageIcon from '@mui/icons-material/Language';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import PersonOutline from '@mui/icons-material/PersonOutline';
import { useTranslation } from 'react-i18next';
import { useUser } from './UserContext';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontSize: 8,
    right: -12,
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: 'white',
  },
}));

const NavigationRow = () => {
  const location = useLocation();
  const [value, setValue] = useState(location.pathname);
  const { t } = useTranslation();
  const { user } = useUser();

  React.useEffect(() => {
    const isAccountRoute = location.pathname.startsWith('/account');
    setValue(isAccountRoute ? '/account' : location.pathname);
  }, [location]);

  const promptUpgrade = !user.isLoggedIn;

  return (
    <div id="navigation-row">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction
          label={t('Translate')}
          value="/translator"
          icon={<LanguageIcon />}
          component={Link}
          to="/translator"
        />
        <BottomNavigationAction
          label={t('Spell Check')}
          value="/spell-checker"
          icon={<SpellcheckIcon />}
          component={Link}
          to="/spell-checker"
        />
        <BottomNavigationAction
          label={t('About')}
          value="/about"
          icon={<HelpOutlineIcon />}
          component={Link}
          to="/about"
        />
        {/* <BottomNavigationAction
          label={promptUpgrade ? t('Support us') : t('Account')}
          value="/account"
          icon={
            promptUpgrade ? (
              <StyledBadge badgeContent={'NEW'} color="primary">
                <VolunteerActivismOutlinedIcon />
              </StyledBadge>
            ) : (
              <PersonOutline />
            )
          }
          component={Link}
          to="/account"
        /> */}
      </BottomNavigation>
    </div>
  );
};

export default NavigationRow;
