import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import OtpInput from 'react-otp-input';

import PrimaryButton from '../PrimaryButton';
import DialogSubtitle from '../DialogSubtitle';
import { useConfig } from '../ConfigContext';
import { useUser } from '../UserContext';
import { useNotification } from '../NotificationContext';

export default function Account() {
  const { apiUrl } = useConfig();
  const [verificationCode, setVerificationCode] = React.useState('');
  const { user, login, checkout } = useUser();
  const [loading, setLoading] = React.useState(false);
  const { showNotification } = useNotification();

  const sendVerificationCode = async () => {
    const url = `${apiUrl}/api/accounts/signup/verify/`;
    setLoading(true);
    try {
      await axios.get(url, {
        params: { code: verificationCode, email: user.email },
      });
      await login(user.email, user.password);
      await checkout(user.email);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        showNotification(error.response.data.detail);
      } else {
        showNotification('Error verifying email:' + JSON.stringify(error));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ paddingTop: 3, fontSize: 22, fontWeight: 500 }}
        >
          Enter your verification code
        </Typography>
        <Box sx={{ padding: 3 }}>
          <DialogSubtitle>
            Verify your email address by entering the 6-digit code you received
            via email below.
          </DialogSubtitle>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <OtpInput
              value={verificationCode}
              onChange={setVerificationCode}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: '3rem',
                height: '3rem',
                margin: '0 1rem',
                fontSize: '1.5rem',
                borderRadius: 4,
                border: '1px solid rgba(0,0,0,0.3)',
              }}
            />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <PrimaryButton onClick={sendVerificationCode} disabled={loading}>
              Verify
            </PrimaryButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
