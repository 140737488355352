import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";

const MyTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 10,
  },
});

export default MyTextField;
