import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import SwitchToLogin from '../SwitchToLogin';
import PrimaryButton from '../PrimaryButton';
import DialogSubtitle from '../DialogSubtitle';
import MyTextField from '../MyTextField';
import { useConfig } from '../ConfigContext';
import { useNotification } from '../NotificationContext';

export default function Account() {
  const { apiUrl } = useConfig();
  const [email, setEmail] = React.useState('');
  const { showNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const resetPassword = async () => {
    setLoading(true);
    const url = `${apiUrl}/api/accounts/password/reset/`;
    try {
      await axios.post(url, { email: email });
      alert('Password reset email has been sent');
    } catch (error) {
      console.log(error.response);
      if (error.response && error.response.data) {
        showNotification(error.response.data);
      } else {
        alert('Error verifying email:' + JSON.stringify(error));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Forgot password
        </Typography>
        <Box sx={{ padding: 3 }}>
          <DialogSubtitle>
            Enter your email address below, we will send you a link to reset
            your password
          </DialogSubtitle>
          <MyTextField
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <PrimaryButton onClick={resetPassword} disabled={loading}>
              Reset Password
            </PrimaryButton>
          </Box>
        </Box>
        {/* <ErrorSnackbar errorMessage={errorMessage} /> */}
      </Paper>
      <SwitchToLogin
        text="Don't have an account?"
        linkHref="/account"
        linkText="Signup"
      />
    </Box>
  );
}
