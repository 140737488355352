import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import axios from 'axios';

import SwitchToLogin from '../SwitchToLogin';
import PrimaryButton from '../PrimaryButton';
import DialogSubtitle from '../DialogSubtitle';
import MyTextField from '../MyTextField';
import { useConfig } from '../ConfigContext';
import { useNotification } from '../NotificationContext';
import { useParams } from 'react-router-dom';

export default function Account() {
  const { apiUrl } = useConfig();
  const [loading, setLoading] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const { showNotification } = useNotification();
  const { key } = useParams();

  const resetPassword = async () => {
    setLoading(true);
    const url = `${apiUrl}/api/accounts/password/reset/verified/`;
    try {
      await axios.post(url, { code: key, password: password });
      alert('Password successfully reset');
      window.location.href = '/account/login';
    } catch (error) {
      console.log(error.response);
      if (error.response && error.response.data) {
        showNotification(error.response.data);
      } else {
        alert('Error setting new password:' + JSON.stringify(error));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Reset password
        </Typography>
        <Box sx={{ padding: 3 }}>
          <DialogSubtitle>Enter your new password below</DialogSubtitle>
          <MyTextField
            label="Password"
            type="password"
            name="password"
            variant="outlined"
            fullWidth
            required
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box
            sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}
          >
            <PrimaryButton onClick={resetPassword} disabled={loading}>
              Reset Password
            </PrimaryButton>
          </Box>
        </Box>
        {/* <ErrorSnackbar errorMessage={errorMessage} /> */}
      </Paper>
      <SwitchToLogin
        text="Don't have an account?"
        linkHref="/account"
        linkText="Signup"
      />
    </Box>
  );
}
