import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

import { useConfig } from './ConfigContext';
import { useNotification } from './NotificationContext';

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const { apiUrl } = useConfig();

  const [user, setUser] = useState({
    isLoggedIn: localStorage.getItem('user-key') ? true : false,
    email: null,
    password: null,
    plan: null,
    key: null,
    firstName: null,
    lastName: null,
    isPaying: localStorage.getItem('user-key') ? true : false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const { showNotification } = useNotification();

  const updateUser = (userData) => {
    setUser({ ...user, ...userData });
  };

  React.useEffect(() => {
    const key = localStorage.getItem('user-key');
    if (key) {
      axios.defaults.headers.common['AUTHORIZATION'] = `Token ${key}`;
      getUserDetails();
    } else {
      setIsLoading(false);
    }
  }, []);

  const login = async (email, password) => {
    const loginUrl = `${apiUrl}/api/accounts/login/`;
    try {
      // axios: remove the default AUTHORIZATION header, if present
      delete axios.defaults.headers.common['AUTHORIZATION'];
      const response = await axios.post(loginUrl, { email, password });
      const key = response.data.token;
      localStorage.setItem('user-key', key);
      axios.defaults.headers.common['AUTHORIZATION'] = `Token ${key}`;
      const newUser = await getUserDetails();
      setUser({ ...newUser, isLoggedIn: true, email, key });
      return true;
    } catch (error) {
      if (error.response) {
        if (error.response && error.response.data.detail) {
          showNotification(error.response.data.detail);
        } else {
          showNotification(error.response.data);
        }
      } else {
        showNotification(error.message);
      }
      return false;
    }
  };

  const socialLogin = async ({ code }) => {
    const socialLoginUrl = `${apiUrl}/users/google-login/`;
    try {
      delete axios.defaults.headers.common['AUTHORIZATION'];
      const response = await axios.post(socialLoginUrl, { code });
      const key = response.data.key;
      localStorage.setItem('user-key', key);
      axios.defaults.headers.common['AUTHORIZATION'] = `Token ${key}`;
      const newUser = await getUserDetails();
      setUser({ ...newUser, isLoggedIn: true, key });
      return true;
    } catch (error) {
      if (error.response) {
        if (error.response && error.response.data.detail) {
          showNotification(error.response.data.detail);
        } else {
          showNotification(error.response.data);
        }
      } else {
        showNotification(error.message);
      }
      return false;
    }
  };

  const logout = async () => {
    const logoutUrl = `${apiUrl}/api/accounts/logout/`;
    try {
      localStorage.removeItem('user-key');
      delete axios.defaults.headers.common['AUTHORIZATION'];
      setUser({
        ...user,
        isLoggedIn: false,
        isPaying: false,
        email: null,
        key: null,
      });
      await axios.get(logoutUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const register = async (firstName, lastName, email, password) => {
    try {
      await axios.post(`${apiUrl}/api/accounts/signup/`, {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      });
      setUser({ ...user, firstName, lastName, email, password });
      return true;
    } catch (error) {
      if (error.response) {
        showNotification(error.response.data);
      } else {
        showNotification(error.message);
      }
      return false;
    }
  };

  const checkout = async () => {
    try {
      const checkoutUrl = `${apiUrl}/users/checkout/`;
      const response = await axios.post(checkoutUrl);

      const CHECKOUT_SESSION_ID = response.data['CHECKOUT_SESSION_ID'];
      const STRIPE_PUBLIC_KEY = response.data['STRIPE_PUBLIC_KEY'];

      const stripe = await loadStripe(STRIPE_PUBLIC_KEY);

      const result = await stripe.redirectToCheckout({
        sessionId: CHECKOUT_SESSION_ID,
        // Got error stripe.redirectToCheckout: Do not provide other parameters when providing sessionId. Specify all parameters on your server when creating the CheckoutSession.
        // customerEmail: email,
      });

      if (result.error) {
        showNotification(result.error.message);
        console.error(result.error);
      }
    } catch (error) {
      showNotification(error.message);
      console.error(error);
    }
  };

  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/users/user-details/`);
      const newUser = {
        ...user,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        isLoggedIn: true,
        isPaying: response.data.is_paying_subscriber,
      };
      setUser(newUser);
      return newUser;
    } catch (error) {
      if (error.response) {
        showNotification(JSON.stringify(error.response.data));
      } else if (error.message === 'Network Error') {
        console.log('Network error');
      } else {
        showNotification(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        socialLogin,
        logout,
        register,
        checkout,
        updateUser,
        isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
