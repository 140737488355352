import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { forEach, trim, reduce, isString, every } from 'lodash';
import { LinearProgress } from '@mui/material';
import { useQuery, useQueryClient } from 'react-query';

const fetchDictionary = async (filename: string) => {
  const { data } = await axios.get(`/${filename}`);
  return data;
};

function splitMasculineFromFeminine() {
  const findMasculineRegex = new RegExp(/(.*)\(a\)$/, 'gm');
  const newEntries: Record<string, any> = {};

  forEach(window.dictionaries['tet-en'], (englishWords, tetun) => {
    const maculinePart = findMasculineRegex.exec(tetun);
    if (maculinePart) {
      newEntries[maculinePart[1]] = englishWords;
      newEntries[`${maculinePart[1]}a`] = englishWords;
    }
  });
  forEach(newEntries, (englishWords, tetun) => {
    window.dictionaries['tet-en'][tetun] = englishWords;
  });
}

function findMoreEnglishTranslationsFromTetun() {
  forEach(window.dictionaries['tet-en'], (englishWords: any, tetun: any) => {
    // for each, strip the part of speech, then split on ', ' and '; '
    const flattenedTranslations = reduce(
      englishWords,
      (accum: any[], oneTranslation: { t: string }) => {
        const partOfSpeechRegex = /[a-z /]+\./;
        let translations = oneTranslation.t.replace(partOfSpeechRegex, '');
        // remove parenthesis, used for notes about the translation
        translations = translations.replace(/\([\w, .]+\)/g, '');
        const translationsArray = translations.split(/[,;] /);
        accum.push(...translationsArray);
        return accum;
      },
      []
    );
    flattenedTranslations.forEach((english: any) => {
      const trimmedEnglish = trim(english);
      // if more than 2 words, pass
      if (trimmedEnglish.split(' ').length > 1) return;
      if (trimmedEnglish.length === 0) return;
      if (!(trimmedEnglish in window.dictionaries['en-tet'])) {
        window.dictionaries['en-tet'][trimmedEnglish] = [tetun];
      }
    });
  });
  return Promise.resolve();
}

function putEnTetInNewFormat() {
  // if en-tet dict is still using the old format, put it in the new standardised format
  forEach(window.dictionaries['en-tet'], (tetunWords, englishWord) => {
    if (every(tetunWords, isString)) {
      const newTetunWords = tetunWords.map((tetunWord: string) => ({
        t: tetunWord,
      }));
      window.dictionaries['en-tet'][englishWord] = newTetunWords;
    }
  });
}

const fetchTetDictionary = () => fetchDictionary('tet.json');
const fetchEngDictionary = () => fetchDictionary('eng.json');

const DataLoader: React.FC = () => {
  const queryClient = useQueryClient();
  const [showDone, setShowDone] = useState(false);
  const [hideProgressBar, setHideProgressBar] = useState(false);
  const today = new Date().toDateString();

  useEffect(() => {
    const lastShownDate = localStorage.getItem('lastProgressBarShown');
    setHideProgressBar(lastShownDate === today);
  }, []);

  const { isLoading: isLoadingTet } = useQuery(
    'tetDictionary',
    fetchTetDictionary,
    {
      staleTime: Infinity, // Never refetch
    }
  );

  const { isLoading: isLoadingEng } = useQuery(
    'engDictionary',
    fetchEngDictionary,
    {
      staleTime: Infinity, // Never refetch
    }
  );

  const isLoading = isLoadingTet || isLoadingEng;

  // Trigger a refetch when the component mounts
  useEffect(() => {
    const fetchDataAndProcess = async () => {
      // Fetch tet dictionary
      const tetData = await queryClient.fetchQuery('tetDictionary', () =>
        fetchDictionary('tet.json')
      );
      window.dictionaries['tet-en'] = tetData;

      // Fetch eng dictionary
      const engData = await queryClient.fetchQuery('engDictionary', () =>
        fetchDictionary('eng.json')
      );
      window.dictionaries['en-tet'] = engData;

      splitMasculineFromFeminine();
      findMoreEnglishTranslationsFromTetun();
      putEnTetInNewFormat();
    };

    fetchDataAndProcess();
  }, [queryClient]);

  useEffect(() => {
    if (!isLoading) {
      setShowDone(true);
      localStorage.setItem('lastProgressBarShown', today);
      setTimeout(() => {
        setShowDone(false);
      }, 1000);
    }
  }, [isLoading]);

  return (
    <div>
      {!hideProgressBar && isLoading && <LinearProgress />}
      {!hideProgressBar && !isLoading && showDone && (
        <LinearProgress variant="determinate" value={100} />
      )}
    </div>
  );
};

export default DataLoader;
