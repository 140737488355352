import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const langs = [
  ["tet", "Tetun"],
  ["en", "English"],
];

const LocaleChanger = () => {
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  useEffect(() => {
    window.localStorage.setItem("interfaceLanguageCode", selectedLang);
    i18n.changeLanguage(selectedLang);
  }, [selectedLang, i18n]);

  return (
    <FormControl
      variant="outlined"
      className="locale-changer"
      sx={{ width: "100%", mt: 3 }}
    >
      <InputLabel id="language-select-label">
        {t("Display tetun.org in:")}
      </InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value)}
        label="Display tetun.org in"
      >
        {langs.map((lang, index) => (
          <MenuItem key={`Lang${index}`} value={lang[0]}>
            {lang[1]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocaleChanger;
