import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Button,
  List,
  ListItem,
  Typography,
  CircularProgress,
} from '@mui/material';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

import find from 'lodash/find';
import trim from 'lodash/trim';
import startCase from 'lodash/startCase';

import NavigationRow from '../NavigationRow';
import DataLoader from '../DataLoader';
import LanguagePicker from '../LanguagePicker';
import InputMultilineAutosize from '../InputMultilineAutosize';
import { closestWord } from '../utils/stringSimilarity';
import useDebounce from '../helpers/react-utils';
import { LanguageSource } from '../types';
import { useNotification } from '../NotificationContext';
import CopyClipboardButton from '../CopyClipboardButton';

interface ItemProps {
  partOfSpeech?: string;
  backTranslation?: string;
  content: string;
  exampleTet?: string;
  exampleEn?: string;
}

const TranslationRow = ({ item }: { item: ItemProps }) => {
  return (
    <Box onClick={() => {}} sx={{ flex: 1, mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        {Boolean(item.partOfSpeech) && (
          <Box
            sx={{
              flex: 1,
              maxWidth: '100px',
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <Typography
              sx={{
                fontSize: '0.9rem',
                position: 'relative',
                bottom: '-5px',
                marginRight: '5px',
              }}
            >
              {item.partOfSpeech}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            flex: item.backTranslation ? 2 : 4,
            flexGrow: item.backTranslation ? 1 : 15,
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: '1.2rem' }}>
            {item.content}
          </Typography>
        </Box>
        {Boolean(item.backTranslation) && (
          <Box sx={{ flex: 2, pt: 0.375 }}>
            <Typography>{item.backTranslation}</Typography>
          </Box>
        )}
      </Box>

      {Boolean(item.exampleTet) && (
        <Box sx={{ mt: 0.75, color: '#666' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Typography>{item.exampleTet}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Typography>{item.exampleEn}</Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface TranslatedParagraphProps {
  text: string;
}

const TranslatedParagraph: React.FC<TranslatedParagraphProps> = ({ text }) => {
  return (
    <Typography variant="body1" component="p">
      {text}
    </Typography>
  );
};

type OneTranslation = {
  t: string;
  e_tet?: string;
  e_tpi?: string;
  e_en?: string;
};

class Translation {
  original: string;
  languageSource: LanguageSource;
  exampleTet?: string;
  exampleEn?: string;
  partOfSpeech?: string;
  content: string;
  backTranslation?: string;

  constructor(
    original: string,
    stringRepr: OneTranslation,
    languageSource: LanguageSource
  ) {
    this.original = original;
    this.languageSource = languageSource;

    const t = stringRepr.t;
    this.exampleTet = stringRepr.e_tet || stringRepr.e_tpi;
    this.exampleEn = stringRepr.e_en;

    if (this.languageSource === 'tet') {
      const partOfSpeechRegex = /[a-z /]+\./;
      const partOfSpeech = t.match(partOfSpeechRegex);
      this.partOfSpeech = partOfSpeech ? partOfSpeech[0] : undefined;
      this.content = partOfSpeech ? t.replace(partOfSpeechRegex, '') : t;
    } else {
      this.content = t;
    }
  }

  setBackTranslation(): void {
    if (!(this.content in window.dictionaries['tet-en'])) {
      return;
    }
    const backTranslationCandidates =
      window.dictionaries['tet-en'][this.content];
    const fullBackTranslation = find(backTranslationCandidates, (s) =>
      s.t.includes(this.original)
    );
    if (!fullBackTranslation) return;
    const translationPart = fullBackTranslation.t;

    const backTrans = find(translationPart.split(/[;]+/), (c: string) =>
      c.includes(this.original)
    );
    if (backTrans && trim(backTrans) !== trim(this.original)) {
      this.backTranslation = backTrans;
      this.exampleTet = fullBackTranslation.e_tet || fullBackTranslation.e_tpi;
      this.exampleEn = fullBackTranslation.e_en;
    }
  }
}

const Translator = () => {
  const [toTranslate, setToTranslate] = useState('');
  const [translations, setTranslations] = useState([]);
  const [src_lang, setSrcLang] = useState<LanguageSource>('en');
  const [tgt_lang, setTgtLang] = useState<LanguageSource>('tet');
  const [suggestion, setSuggestion] = useState('');
  const [offerTranslate, setOfferTranslate] = useState(false);
  const [translationResponseFromApi, setTranslationResponseFromApi] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();

  const { t } = useTranslation();

  const loadLastUsedLanguage = async () => {
    const src_lang = localStorage.getItem('@src_lang') || 'en';
    const tgt_lang = localStorage.getItem('@tgt_lang') || 'tet';

    setSrcLang(src_lang as LanguageSource);
    setTgtLang(tgt_lang as LanguageSource);
  };

  useEffect(() => {
    loadLastUsedLanguage();
  }, []);

  const getTranslations = (text: string) => {
    if (!text) {
      text = toTranslate;
    }

    setToTranslate(text);

    let newTranslations;
    const cleanedText = text.trim().replace('’', "'").toLowerCase();
    const dictionary = window.dictionaries[`${src_lang}-${tgt_lang}`];

    if (!dictionary) return;

    newTranslations =
      dictionary[cleanedText] || dictionary[startCase(cleanedText)];

    if (newTranslations) {
      newTranslations = newTranslations.map(
        (translation: OneTranslation) =>
          new Translation(cleanedText, translation, src_lang)
      );
      if (src_lang === 'en' && tgt_lang === 'tet') {
        newTranslations.forEach((translation: Translation) =>
          translation.setBackTranslation()
        );
      }
      setOfferTranslate(false);
      setTranslationResponseFromApi([]);
    }

    newTranslations = newTranslations || [];

    if (!newTranslations.length && cleanedText.length >= 3) {
      attemptFindSimilar(cleanedText);
    } else {
      setSuggestion('');
    }
    setTranslations(newTranslations);
  };

  const debouncedGetTranslations = useDebounce(getTranslations);

  const handleToTranslateChange = (text: string) => {
    setToTranslate(text);
    if (text.length < 25) {
      debouncedGetTranslations();
    } else {
      debouncedGetTranslations.cancel();
      setTranslations([]);
    }
    const hasText = text.trim().length > 0;
    setOfferTranslate(hasText);
  };

  const changeLanguage = async (src: LanguageSource, tgt: LanguageSource) => {
    setSrcLang(src);
    setTgtLang(tgt);
    if (translationResponseFromApi.length) {
      setOfferTranslate(true);
    }
    localStorage.setItem('@src_lang', src);
    localStorage.setItem('@tgt_lang', tgt);
    debouncedGetTranslations();
  };

  const attemptFindSimilar = (enteredString: string) => {
    const dictionary = window.dictionaries[`${src_lang}-${tgt_lang}`];
    if (!dictionary) return;
    const closest = closestWord(enteredString, dictionary); // Assuming closestWord is defined
    setSuggestion(closest);
  };

  const enterSuggestion = () => {
    setToTranslate(suggestion);
    getTranslations(suggestion);
    setSuggestion('');
  };

  const clearText = () => {
    setToTranslate('');
    setTranslations([]);
    setSuggestion('');
    setTranslationResponseFromApi([]);
    setOfferTranslate(false);
  };

  const translateApi = () => {
    if (loading) return;

    setLoading(true);

    const apiUrl =
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:8000/api'
        : 'https://api.tetun.org/api';

    const headers: { [key: string]: string } = {};
    const key = localStorage.getItem('user-key');
    if (key) {
      headers['AUTHORIZATION'] = `Token ${key}`;
    }
    axios
      .post(
        apiUrl,
        {
          src: src_lang === 'tet' ? 'tdt' : src_lang,
          tgt: tgt_lang === 'tet' ? 'tdt' : tgt_lang,
          q: toTranslate.trim(),
        },
        { headers }
      )
      .then((response) => {
        setLoading(false);
        let result = response.data.result;
        result = result.charAt(0).toUpperCase() + result.slice(1);
        setTranslationResponseFromApi(result.split('<p>'));
        setOfferTranslate(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message === 'Network Error') {
          showNotification(
            'Network Error - ' + t('need_internet_to_translate')
          );
        } else if (error.response && error.response.data) {
          showNotification(error.response.data);
        } else {
          showNotification(error.message);
        }
      });
  };

  const getTextToCopy = () => {
    return translationResponseFromApi.join('\n');
  };

  return (
    <div
      style={{
        backgroundColor: 'rgb(250,250,250)',
        minHeight: '100%',
      }}
    >
      <DataLoader />
      <Container
        maxWidth="sm"
        sx={{
          justifyContent: 'center',
          height: 1,
          pb: 10,
        }}
      >
        <Box>
          <Box>
            <LanguagePicker
              src_lang={src_lang}
              tgt_lang={tgt_lang}
              onLanguageChange={changeLanguage}
            />
          </Box>
          <div className="search-container">
            <InputMultilineAutosize
              onChange={handleToTranslateChange}
              value={toTranslate}
              onClear={clearText}
            />
          </div>
          {Boolean(translations.length) && (
            <List style={{ marginBottom: '30px' }}>
              {translations.map((item, index) => (
                <ListItem key={index}>
                  <TranslationRow item={item} />
                </ListItem>
              ))}
            </List>
          )}
          {Boolean(suggestion) && (
            <Box display="flex" alignItems="baseline">
              <Typography variant="body1">
                {t('Suggest you search for:')}
              </Typography>
              <Button
                variant="text"
                onClick={enterSuggestion}
                style={{ fontSize: '18px', textTransform: 'none' }}
              >
                {suggestion}
              </Button>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {Boolean(offerTranslate) && (
              <Button
                variant="outlined"
                onClick={translateApi}
                disabled={loading}
                sx={{ mt: 1 }}
              >
                {loading ? <CircularProgress size={20} /> : t('Translate')}
              </Button>
            )}
          </Box>
          {Boolean(translationResponseFromApi.length) && (
            <>
              <List sx={{ mt: 1 }}>
                {translationResponseFromApi.map((item, index) => (
                  <ListItem key={index} sx={{ p: 0, mt: 1 }}>
                    <TranslatedParagraph text={item} />
                  </ListItem>
                ))}
              </List>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CopyClipboardButton
                  textToCopyGetter={getTextToCopy}
                ></CopyClipboardButton>
              </Box>
            </>
          )}
        </Box>
      </Container>
      {!translationResponseFromApi.length && !(toTranslate.length > 25) && (
        <Box
          id="support-us"
          sx={{
            width: '100%',
          }}
        >
          🧡 &nbsp;tetun.org?{' '}
          <a
            href="https://ko-fi.com/raphaelmerx"
            target="_blank"
            rel="noreferrer"
          >
            Support us
          </a>
        </Box>
      )}
      <NavigationRow />
    </div>
  );
};

export default Translator;
