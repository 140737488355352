import * as React from 'react';

import { Navigate, useLocation, Outlet } from 'react-router-dom';

import NavigationRow from '../NavigationRow';
import { useUser } from '../UserContext';
import LogRocket from 'logrocket';

// create an element that will be used for paragraphs
// customer portal link: https://billing.stripe.com/p/login/4gwdRUcIz3ti8hy8ww

export default function Account() {
  const { user, isLoading } = useUser();
  const location = useLocation();

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('jymlud/tetunorg');
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (location.pathname === '/account' || location.pathname === '/account/') {
    if (!user || !user.isLoggedIn) {
      return <Navigate to="/account/upgrade" replace />;
    } else {
      return <Navigate to="/account/details" replace />;
    }
  }

  return (
    <div>
      <Outlet />
      <NavigationRow />
    </div>
  );
}
