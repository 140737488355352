/* eslint-disable react/prop-types */
import React from 'react';
import Button from '@mui/material/Button';

const PrimaryButton = ({ onClick, children, ...props }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      color: 'white',
      borderRadius: '5px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      ...props.sx,
    }}
    onClick={onClick}
    disabled={props.disabled}
  >
    {children}
  </Button>
);

export default PrimaryButton;
