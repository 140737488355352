import { closestWord, wordInDictionary } from "./stringSimilarity";

const getSuggestionForWord = (wordToCheck) => {
  if (wordInDictionary(wordToCheck, window.dictionaries["tet-en"])) return "";
  let suggestion = closestWord(wordToCheck, window.dictionaries["tet-en"]);
  if (!suggestion) return "";
  // if word starts with an upper case, suggest an upper case
  if (wordToCheck.substr(0, 1).toUpperCase() === wordToCheck.substr(0, 1)) {
    suggestion = suggestion.substr(0, 1).toUpperCase() + suggestion.substr(1);
  }
  return suggestion;
};

export default class SuggestionTooltip {
  constructor(view) {
    this.tooltip = document.createElement("div");
    this.tooltip.className = "tooltip";
    view.dom.parentNode.appendChild(this.tooltip);

    this.update(view, null);
  }

  positionTooltip(view, state) {
    // Otherwise, reposition it and update its content
    this.tooltip.classList.remove("hidden");
    const { from, to } = state.selection;
    // These are in screen coordinates
    const start = view.coordsAtPos(from);
    const end = view.coordsAtPos(to);
    // The box in which the tooltip is positioned, to use as base
    const box = this.tooltip.offsetParent.getBoundingClientRect();
    // Find a center-ish x position from the selection endpoints (when
    // crossing lines, end may be more to the left)
    const left = Math.max((start.left + end.left) / 2, start.left + 1);
    this.tooltip.style.left = `${left - box.left}px`;
    this.tooltip.style.bottom = `${box.bottom - start.top}px`;
  }

  populateTooltipWithSuggestion(suggestion) {
    // clear previous span
    this.tooltip.textContent = "";

    this.tooltipButton = document.createElement("span");
    this.tooltipButton.textContent = suggestion;
    this.tooltipButton.onmousedown = (e) => {
      e.preventDefault();
      this.acceptSuggestion();
    };
    this.tooltip.appendChild(this.tooltipButton);
  }

  acceptSuggestion() {
    this.view.dispatch(
      // not sure why we need the +1 here, to be investigated
      this.state.tr.replaceWith(
        this.from,
        this.to,
        this.state.schema.text(this.suggestion)
      )
    );
  }

  attachHandlerArrowKey(view, state, suggestion) {
    const tooltipContent = document.querySelector(".tooltip span");
    // eslint-disable-next-line no-param-reassign
    view.dom.onkeydown = (e) => {
      if (this.tooltip.classList.contains("hidden")) return;
      if (e.keyCode === 38) {
        e.preventDefault();
        tooltipContent.classList.add("highlighted");
      }
      if (
        e.keyCode === 40 &&
        tooltipContent.classList.contains("highlighted") &&
        !tooltipContent.parentElement.classList.contains("hidden")
      ) {
        e.preventDefault();
        document.querySelector(".tooltip span").classList.remove("highlighted");
      }
      if (
        e.keyCode === 13 &&
        tooltipContent.classList.contains("highlighted") &&
        !tooltipContent.parentElement.classList.contains("hidden")
      ) {
        e.preventDefault();
        this.acceptSuggestion(suggestion, view, state);
      }
    };
  }

  update(view, lastState) {
    const state = view.state;
    // Don't do anything if the document/selection didn't change
    if (
      lastState &&
      lastState.doc.eq(state.doc) &&
      lastState.selection.eq(state.selection)
    )
      return;

    const resolvedCursorPos = state.selection.$from;
    const absolutePos = resolvedCursorPos.pos;
    const allProblems = view.dom.querySelectorAll(".problem");

    // find problem under the cursor, if any
    this.suggestion = null;
    allProblems.forEach((problemSpan) => {
      const from = Number(problemSpan.getAttribute("from"));
      const to = Number(problemSpan.getAttribute("to"));
      if (from <= absolutePos && absolutePos <= to) {
        const wordToCheck = problemSpan.getAttribute("word");
        this.suggestion = getSuggestionForWord(wordToCheck);
        this.from = from;
        this.to = to;
      }
    });

    if (!this.suggestion) {
      this.tooltip.classList.add("hidden");
      return;
    }

    // this.suggestion = suggestion;
    this.view = view;
    this.state = state;
    this.positionTooltip(view, state);
    this.populateTooltipWithSuggestion(this.suggestion);
    this.attachHandlerArrowKey(view, state, this.suggestion);
  }

  destroy() {
    this.tooltip.remove();
  }
}
