import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import SecondaryButton from '../SecondaryButton';
import { useUser } from '../UserContext';

export default function Logout() {
  const navigate = useNavigate();
  const { logout } = useUser();

  React.useEffect(() => {
    logout();
  }, []);

  const handleBackToLogin = () => {
    navigate('/account/upgrade');
  };

  return (
    <Box>
      <Paper
        elevation={3}
        style={{
          borderRadius: 15,
          paddingTop: 10,
          maxWidth: 700,
          margin: 'auto',
          marginTop: 50,
        }}
      >
        <Typography
          align="center"
          sx={{ padding: 3, fontSize: 22, fontWeight: 500 }}
        >
          Logged out successfully
        </Typography>
        <Box sx={{ padding: 3 }}></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingY: 2,
            paddingX: 3,
          }}
        >
          <SecondaryButton onClick={handleBackToLogin}>
            Back to login
          </SecondaryButton>
        </Box>
      </Paper>
    </Box>
  );
}
