/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Check from '@mui/icons-material/Check';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';

const CopyClipboardButton = ({ textToCopyGetter }) => {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const { t } = useTranslation();
  let timeoutID;

  const copyClipboard = () => {
    const textToCopy = textToCopyGetter();
    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowCopiedMessage(true);
      timeoutID = setTimeout(() => {
        setShowCopiedMessage(false);
        timeoutID = undefined;
      }, 2000);
    });
  };

  useEffect(() => {
    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
        timeoutID = undefined;
      }
    };
  }, []);

  return (
    <Button
      onClick={copyClipboard}
      variant="outlined"
      startIcon={showCopiedMessage ? <Check /> : <FileCopyOutlined />}
    >
      {showCopiedMessage ? t('Copied') : t('Copy')}
    </Button>
  );
};

export default CopyClipboardButton;
