import React from 'react';
import Typography from '@mui/material/Typography';

const DialogSubtitle = ({ children }) => (
  <Typography
    gutterBottom
    color="#4f4f4f"
    align="center"
    sx={{ marginBottom: 3 }}
  >
    {children}
  </Typography>
);

export default DialogSubtitle;
